import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'


const MxReportpage = () => (
    <Layout>
        <SEO title="MxReport" keywords={[`Nesting`, `Production Environment`, `react`]} />
        <div className="BlueGradient">
            <div className="--headerClear"></div>
            <section className="--sectionPaceM --centerMargin --maxwidthS --edgePadding --centerTxt --heroAnimation ">
                <h2><span>MxReport</span></h2>
                <p>A reporting tool customizable for your shop. Pinpoint your documentation across your entire business.</p>
            </section>
         </div>
         <section className="--centerMargin --maxwidthL">
            <article id="--productOverview">
                <h5>Product Overview</h5>
                <p className="DarkP">Accelerate your production with MX Productivity Tools. Speed jobs through part sorting, automate shop floor data collection and take control of your shop floor. Customize your reports to fit your needs. MxReport supports easy Barcoded Information.</p>
            </article>
        </section>
    <section className="--sectionPace --centerTxt">
      <h2 className="DarkH">Expand MxStation with additional modules</h2>
      <p className="DarkP">Customize and control your suite of Mx products based on your shop needs. </p>
      <div className="--headerClear"></div>
      <div className="MXproducts" id="MxProductPage">
        <Link to="/mx"><span><h4>MxManager</h4></span></Link>
          <Link to="/mxmrp"> 
            <div id="ProductChip">
              <h4>MxMRP</h4>
              <hr></hr>
              <p>Real-Time Monitoring</p>
            </div>
          </Link>
          <Link to="/mxpallet"> 
            <div id="ProductChip">
              <h4>MxPallet</h4>
              <hr></hr>
              <p>Expedite finished pallets</p>
            </div>
          </Link>
          <Link to="/mxticket"> 
            <div id="ProductChip">
              <h4>MxTicket</h4>
              <hr></hr>
              <p>Smarter Labels & Tickets</p>
            </div>
          </Link>
          <Link to="/mxstation"> 
            <div id="ProductChip">
              <h4>MxStation</h4>
              <hr></hr>
              <p>Process Queue Control</p>
            </div>
          </Link>
          <Link to="/mxreport"> 
            <div id="ProductChip">
              <h4>MxReport</h4>
              <hr></hr>
              <p>Pinpoint Documentation</p>
            </div>
          </Link>
        </div>
      </section>
      <section className="--maxwidthL --edgePadding --sectionPace --centerMargin">
      <article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Would you like a demo?</h2>
          <p className="DarkP">Let us walk you through this software and answer your questions in a one-on-one webinar.</p>
        </article>
        <div className="--headerClear"></div>
        <section className="--maxwidthM --grid5050 --centerMargin --alignCenter">
        <a href="tel:9527465125" className="--bkgContainer --alignCenter --gridGapS" id="contactLinks">
            <div id="phoneIcon"></div>
            <article className="--grid">
              <span className="--flex --alignCenter --gridGapS"><h5 className="DarkH">Call Us</h5><p class="DarkP --margin0">7am–5pm CST</p></span>
              <p class="DarkP --margin0">(952) 746-5125</p>
            </article>
          </a>
          <a href="mailto:info@ncell.com" className="--bkgContainer --justifyLeft --gridGapS" id="contactLinks">
            <div id="emailIcon"></div>
            <article>
              <h5 className="DarkH">Email Us</h5>
              <p class="DarkP --margin0">info@ncell.com</p>
            </article>
          </a>
          </section>
          </section>
    </Layout>
)

export default MxReportpage